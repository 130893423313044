.white-arrow[x-placement^="top"] .arrow::before {
  border-top-color: white !important;
}

.white-arrow[x-placement^="bottom"] .arrow::before {
  border-bottom-color: white !important;
}

.white-arrow[x-placement^="left"] .arrow::before {
  border-left-color: white !important;
}

.white-arrow[x-placement^="right"] .arrow::before {
  border-right-color: white !important;
}

.black-shadowed-arrow[x-placement^="top"] .arrow::before {
  top: -5px;
  border-top-color: #343434 !important;
}

.black-shadowed-arrow[x-placement^="bottom"] .arrow::before {
  top: 6px;
  border-bottom-color: #343434 !important;
}

.black-shadowed-arrow[x-placement^="left"] .arrow::before {
  right: -5px;
  border-left-color: #343434 !important;
}

.black-shadowed-arrow[x-placement^="right"] .arrow::before {
  left: 6px;
  border-right-color: #343434 !important;
}

.demo-invite[x-placement^="bottom"] .arrow::before {
  top: 6px;
  border-bottom-color: red !important;
}

.duplicateTooltip {
  box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
    0px 16px 32px rgba(22, 34, 51, 0.16);
  border-radius: 4px;
  background: #FFFFFF;
  padding: 0px;
}

.translateTooltipR {
  transform: translateX(10px);
}
.translateTooltipL {
  transform: translateX(-10px);
}
.translateTooltipT {
  transform: translateY(-5px);
}
.translateTooltipB {
  transform: translateY(5px);
}

.tooltip-inner {
  max-width: none;
}

.editorTooltip {
  box-shadow: 0px 16px 32px 0px rgba(22, 34, 51, 0.16),
    0px 4px 12px -4px rgba(22, 34, 51, 0.12);
  border-radius: 4px;
  background: #ffffff;
}

.editorTooltip,
.tooltip.show {
  opacity: 1;
}

.guided-tour-container {
}
.guided-tour-inner {
  background-color: var(--neutral-900, #343434);
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  max-width: 100%;
  padding: 16px;
  position: relative;
  width: 260px;
  z-index: 10000000000000;
}

.guided-tour-arrow {
  box-shadow: 0px 16px 32px 0px rgba(22, 34, 51, 0.16),
    0px 4px 12px -4px rgba(22, 34, 51, 0.12);
}

.guided-tour-next-btn {
   padding: 6px; 
   border-radius: 4px; 
   border: 1px solid #E6E6E8; 
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: var(--neutral-900, #343434) !important;
}

