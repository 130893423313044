.option {
  height: 42px;
  padding: 0px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #6c6c71;
}

.option:hover {
  background-color: #F1F1F1;
}