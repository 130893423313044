.modal {
  width: 440px;
}
.title {
  text-align: center;
}
.modal-container {
  border-bottom: 1px solid E6E6E8;
  padding: 20px;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close-btn-container {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--neutral100);
}
.close-btn-container:hover {
  background-color: var(--neutral400);
}
.icon {
  margin: 16px;
}
.player-container {
 
}
.modal-bottom {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-top: 1px solid #e6e6e8;
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
.bottom-link {
  padding: 10px;
  text-align: center;
}