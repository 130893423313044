.category {
  width: 100%;
  height: 64px;
  padding: 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
}
.category:hover {
  background-color: var(--neutral400);
}
.category > div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 8px;
}
