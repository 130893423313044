.tooltip-body {
  margin: 5px;
  background: var(--neutral-900, #343434);
  box-shadow: 0px 16px 32px 0px rgba(22, 34, 51, 0.16),
    0px 4px 12px -4px rgba(22, 34, 51, 0.12);
  min-width: 100px;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: start;
}
.tooltip-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close-btn-container {
  display: flex;
  justify-content: flex-end;
}
.close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--neutral100);
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  padding: 6px;
  color: white;
}

.close-btn:hover {
}

.popper {
  background-color: red !important;
}

.black-shadowed-arrow[x-placement^="top"] .arrow::before {
  top: -5px;
  border-top-color: red !important;
}

.black-shadowed-arrow[x-placement^="bottom"] .arrow::before {
  top: 30px;
  border-bottom-color: red !important;
}

.black-shadowed-arrow[x-placement^="left"] .arrow::before {
  right: -5px;
  border-left-color: red !important;
}

.black-shadowed-arrow[x-placement^="right"] .arrow::before {
  left: 6px;
  border-right-color: red !important;
}
