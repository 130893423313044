.simple-centered-modal {
  width: 480px;
  margin-left: 10px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid var(--neutral500);
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 80px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid var(--neutral500);
}

.close-btn-container {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--neutral100);
}
.close-btn-container:hover {
  background-color: var(--neutral400);
}
