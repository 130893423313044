.more-icon {
  background-color: "transparent";
}

.more-icon:hover {
  background-color: #E6E6E8;
  transition: "all 0.4s ease-in-out"
}

.option-div-transition {
  background-color: white;
}

.option-div-transition:hover {
  transition: all 0.2s ease;
  background-color: #F1F1F1;
}