.companyBalanceBanner {
  height: 64px;
  display: flex;
  justify-content: space-between;
  background-color: black;
}
.leftSection {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
}
.titleContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  translate: 0px -4px;
}
.rightSection {
  padding-right: 16px;
  display: flex;
  align-items: center;
  position: relative;
}
.abstractContainer {
  height: 64px;
  width: 143px;
  top: 0;
  right: 0;
  position: absolute;
}
.topUpButton {
  height: 24px;
  padding-right: 8px;
  z-index: 1;
  height: fit-content;
  background-color: var(--neutral900);
  border: none;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
}
