.hover-info {
  position: absolute;
  display: flex;
  border-radius: 8px;
  padding: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #343434;
  z-index: 100;
  width: 300px;
  translate: -143.5px -60px;
  opacity: 0;
}

.fade-in {
  animation-name: fade-in;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.fade-out {
  animation-name: fade-out;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.triangle-point-container {
  position: absolute;
  left: 0;
  top: 0;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  z-index: 10;
}

.triangle-point {
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  background-color: #343434;
  height: 13px;
  width: 9px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
