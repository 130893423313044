.tooltip-container {
  position: relative;
  display: block;
}

.tooltip {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  width: 236px;
  background-color: #343434;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  text-align: left;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: -60%;
  animation: tooltip-move 0.1s linear infinite alternate;
  animation-duration: 0.7s;
  animation-delay: 0.1s;
  /* animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-name: slide;
  animation-play-state: paused;
  animation-timing-function: ease-in;
  animation-duration: 750ms;
  animation-play-state: running; */
}

.tooltip-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@keyframes tooltip-move {
  0% {
    right: -60%;
  }
  25% {
    right: -63%; /* Adjust distance from the element */
  }
  50% {
    right: -63%; /* Adjust distance from the element */
  }
  75% {
    right: -63%; /* Adjust distance from the element */
  }
  100% {
    right: -60%;
  }
}

.tooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px; /* Half the height of the tooltip arrow */
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333 transparent transparent;
}
