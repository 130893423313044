.modal-transfez {
  border-radius: 8px;
  border: none !important;
  width: auto;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
}

.modal-popup {
  border-radius: 4px;
  border: 0px solid transparent;
  width: 438px;
  max-width: 90vw;
}

.centered-popup {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 0px solid transparent;
}

.border-radius-4 {
  border-radius: 4px;
}

.modal-border-radius {
  border-radius: 8px !important;
  overflow: hidden !important;
  border: none !important;
}

.modal-border-radius4 {
  border-radius: 4px !important;
  border: none !important;
  outline: none !important;
}

.modal-enhancement-center {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.modal-add-recipient {
  border-radius: 12px;
  width: auto;
}
.right-card-modal {
  border: 0px solid transparent;
  border-radius: 24px;
}
.right-modal-dark {
  background-color: rgba(0, 0, 0, 0.25);
  transition: all 0.4s linear;
  overflow-y: hidden !important;
}
.custom-backdrop.show {
  opacity: 0;
}

.right-modal-none {
  background-color: transparent;
  transition: all 0.4s linear;
}

.right-modal-content {
  border: 0px solid transparent !important;
}

.modal-close-button {
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.settings {
  box-shadow: 0px 4px 8px rgba(88, 88, 88, 0.06);
}

.hidescrollbar::webkit-scrollbar {
  display: none;
}
.hidescrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modal-full {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 0px;
  z-index: -9999;
}

.modal-slide-down {
  animation-name: slide-down;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.transparent-backdrop {
  background: transparent;
}

@keyframes slide-down {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
