.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.summaryContainer {
  padding-left: 12px;
  padding-right: 12px;
}
.summary {
  width: 100%;
  border: 1px solid var(--neutral500);
  padding: 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.summaryTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.trxAvatar {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral900);
  border-radius: 16px;
}
.divider {
  border-top: 1px solid var(--neutral500);
}
.summaryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bottom {
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}

.buttons {
  width: 100%;
  padding: 20px;
  display: flex;
  gap: 16px;
}
