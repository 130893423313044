/* 
*
* Animation durations:
*
* closing               -> 0.4s
* opening               -> 0.8s
* reveal two cards      -> 2s
* reveal first content  -> 0.5s (should wait for opening and reveal two cards [2.8s])
* scrolling             -> 1s
* 
*/

.cardsDemoModal {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}
.cardsDemoModalOpenAnimation {
  animation-name: blackBlur;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.cardsDemoModalCloseAnimation {
  animation-name: vanishing;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes blackBlur {
  from {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
  to {
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(3px);
  }
}
@keyframes vanishing {
  from {
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(3px);
  }
  to {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
}

.backButtonContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  left: 109px;
  top: 85px;
  z-index: 9999;
  cursor: pointer;
}

.firstLayer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
}
.twoCardsImageContainer {
  animation-name: twoCardsFadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 1s;
  opacity: 0;
}
@keyframes twoCardsFadeIn {
  0% {
    opacity: 0;
    translate: 0px 100px;
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }
  25% {
    opacity: 1;
    translate: 0px 0px;
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }
  75% {
    opacity: 1;
    translate: 0px 0px;
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }
  100% {
    opacity: 1;
    translate: 0px 0px;
    filter: blur(15px);
    -webkit-filter: blur(15px);
  }
}

.secondLayer {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  animation-name: contentsFadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 2.3s;
  opacity: 0;
  translate: 0px 50px;
  display: flex;
  flex-direction: column;
}
@keyframes contentsFadeIn {
  from {
    opacity: 0;
    translate: 0px 50px;
  }
  to {
    opacity: 1;
    translate: 0px 0px;
  }
}
.scrollDownToSecondContent {
  animation-name: scrollDownToSecondContent;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 0s;
}
.scrollDownToThirdContent {
  animation-name: scrollDownToThirdContent;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 0s;
}
.scrollUpToSecondContent {
  animation-name: scrollUpToSecondContent;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 0s;
}
.scrollUpToFirstContent {
  animation-name: scrollUpToFirstContent;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 0s;
}
@keyframes scrollDownToSecondContent {
  from {
    opacity: 1;
    translate: 0px 0px;
    top: 0px;
  }
  to {
    opacity: 1;
    translate: 0px 0px;
    top: -100%;
  }
}
@keyframes scrollDownToThirdContent {
  from {
    opacity: 1;
    translate: 0px 0px;
    top: -100%;
  }
  to {
    opacity: 1;
    translate: 0px 0px;
    top: -200%;
  }
}
@keyframes scrollUpToSecondContent {
  from {
    opacity: 1;
    translate: 0px 0px;
    top: -200%;
  }
  to {
    opacity: 1;
    translate: 0px 0px;
    top: -100%;
  }
}
@keyframes scrollUpToFirstContent {
  from {
    opacity: 1;
    translate: 0px 0px;
    top: -100%;
  }
  to {
    opacity: 1;
    translate: 0px 0px;
    top: 0px;
  }
}
.contentsContainer {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.firstContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 715px;
  height: fit-content;
}
.moreButton {
  width: 191px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #e6e6e880;
  background: #ffffff40;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 48px;
  animation-name: floatingMoreButton;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}
@keyframes floatingMoreButton {
  0% {
    translate: 0px -10px;
  }
  50% {
    translate: 0px 0px;
  }
  100% {
    translate: 0px -10px;
  }
}
.secondContentContainer {
  display: flex;
  position: relative;
  margin-top: -200px;
}
.jackCorporateCardContainer {
  width: 372px;
  position: relative;
  z-index: 10;
}
.jackCorporateCard {
  width: 100%;
  height: 452px;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  border: 1px solid #1ad45952;
  margin-top: 16px;
  overflow: hidden;
}
.jackCorporateCardEnterFromTop {
  animation-name: jackCorporateCardEnterFromTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.jackCorporateCardEnterFromBottom {
  animation-name: jackCorporateCardEnterFromBottom;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.jackCorporateCardExitToTop {
  animation-name: jackCorporateCardExitToTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.jackCorporateCardExitToBottom {
  animation-name: jackCorporateCardExitToBottom;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes jackCorporateCardEnterFromTop {
  from {
    translate: 0px 400px;
  }
  to {
    translate: 0px 0px;
  }
}
@keyframes jackCorporateCardEnterFromBottom {
  from {
    translate: 0px -1000px;
  }
  to {
    translate: 0px 0px;
  }
}
@keyframes jackCorporateCardExitToTop {
  from {
    translate: 0px 0px;
  }
  to {
    translate: 0px 400px;
  }
}
@keyframes jackCorporateCardExitToBottom {
  from {
    translate: 0px 0px;
  }
  to {
    translate: 0px -1000px;
  }
}
.otherCardContainer {
  width: 352px;
  translate: 0px 52px;
  position: relative;
  z-index: 5;
}
.otherCard {
  width: 100%;
  height: 441px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  border-top-left-radius: 0px;
  border-style: solid;
  border-color: var(--neutral800);
  border-left-width: 0px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  overflow: hidden;
  margin-top: 16px;
  padding-left: 10px;
}
.otherCardEnterFromTop {
  animation-name: otherCardEnterFromTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.otherCardEnterFromBottom {
  animation-name: otherCardEnterFromBottom;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.otherCardExitToTop {
  animation-name: otherCardExitToTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.otherCardExitToBottom {
  animation-name: otherCardExitToBottom;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes otherCardEnterFromTop {
  from {
    translate: 0px 600px;
  }
  to {
    translate: 0px 52px;
  }
}
@keyframes otherCardEnterFromBottom {
  from {
    translate: 0px -600px;
  }
  to {
    translate: 0px 52px;
  }
}
@keyframes otherCardExitToTop {
  from {
    translate: 0px 52px;
  }
  to {
    translate: 0px 600px;
  }
}
@keyframes otherCardExitToBottom {
  from {
    translate: 0px 52px;
  }
  to {
    translate: 0px -600px;
  }
}
.cardSampleContainer {
  position: absolute;
  z-index: 20;
  left: calc(50% - 160px);
  bottom: -150px;
}
.cardSampleEnterFromTop {
  animation-name: cardSampleEnterFromTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.cardSampleEnterFromBottom {
  animation-name: cardSampleEnterFromBottom;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.cardSampleExitToTop {
  animation-name: cardSampleExitToTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.cardSampleExitToBottom {
  animation-name: cardSampleExitToBottom;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes cardSampleEnterFromTop {
  from {
    translate: 0px 1000px;
  }
  to {
    translate: 0px 0px;
  }
}
@keyframes cardSampleEnterFromBottom {
  from {
    translate: 0px -400px;
  }
  to {
    translate: 0px 0px;
  }
}
@keyframes cardSampleExitToTop {
  from {
    translate: 0px 0px;
  }
  to {
    translate: 0px 1000px;
  }
}
@keyframes cardSampleExitToBottom {
  from {
    translate: 0px 0px;
  }
  to {
    translate: 0px -400px;
  }
}
.thirdContentContainer {
  width: 907px;
  position: relative;
  margin-top: -100px;
}
.cardTypesContainer {
  display: flex;
  gap: 32px;
  margin-top: 32px;
}
.cardTypeContainer {
  width: 100%;
  height: 352px;
  border: 1px solid var(--neutral800);
  border-radius: 8px;
  background: rgba(34, 34, 34, 0.8);
}
.benefitEnterFromTop {
  animation-name: benefitEnterFromTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.benefitExitToTop {
  animation-name: benefitExitToTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes benefitEnterFromTop {
  from {
    translate: 0px 1000px;
  }
  to {
    translate: 0px 0px;
  }
}
@keyframes benefitExitToTop {
  from {
    translate: 0px 0px;
  }
  to {
    translate: 0px 1000px;
  }
}
.creditEnterFromTop {
  animation-name: creditEnterFromTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.creditExitToTop {
  animation-name: creditExitToTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes creditEnterFromTop {
  from {
    translate: 0px 600px;
  }
  to {
    translate: 0px 0px;
  }
}
@keyframes creditExitToTop {
  from {
    translate: 0px 0px;
  }
  to {
    translate: 0px 600px;
  }
}
.prepaidEnterFromTop {
  animation-name: prepaidEnterFromTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.prepaidExitToTop {
  animation-name: prepaidExitToTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes prepaidEnterFromTop {
  from {
    translate: 0px 400px;
  }
  to {
    translate: 0px 0px;
  }
}
@keyframes prepaidExitToTop {
  from {
    translate: 0px 0px;
  }
  to {
    translate: 0px 400px;
  }
}
.buttonEnterFromTop {
  animation-name: buttonEnterFromTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.buttonExitToTop {
  animation-name: buttonExitToTop;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes buttonEnterFromTop {
  from {
    translate: 0px 1200px;
  }
  to {
    translate: 0px 0px;
  }
}
@keyframes buttonExitToTop {
  from {
    translate: 0px 0px;
  }
  to {
    translate: 0px 1200px;
  }
}
.ellipseContainer {
  position: absolute;
}
.cardTypeTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
}
.cardTypeCapsule {
  height: 28px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  border-radius: 14px;
}
.cardTypeImageContainer {
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 32px;
}
.masterCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 8px;
}
.benefitsContainer {
  width: 100%;
  height: 80px;
  border: 1px solid var(--neutral800);
  border-radius: 8px;
  margin-top: 32px;
  background: rgba(34, 34, 34, 0.8);
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
}
.benefitContainer {
  width: 250px;
  height: 100%;
  padding: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.benefitButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
.scrollButtonsContainer {
  bottom: 46px;
  right: 46px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 9999;
  opacity: 0;
  animation-name: fadeInScrollButtons;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 2.8s;
}
@keyframes fadeInScrollButtons {
  from {
    display: flex;
    opacity: 0;
  }
  to {
    display: flex;
    opacity: 1;
  }
}
.scrollButton {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--neutral800);
  cursor: pointer;
  transition: 0.4s all ease-out;
}
.list {
  margin-top: 32px;
  padding-right: 32px;
}
.list li {
  color: white;
  margin-top: 12px;
}
