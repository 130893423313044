/* demo ribbon */
.lifetime-badge {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 4px;
  background: rgba(185, 252, 0, 0.2);
  align-self: center;
}
.go-to-portal-btn {
  background: #f1f1f1;
  border-radius: 40px;
  padding: 0px 18px;
  margin: 8px 0;
}
.to-jack-btn {
  background-color: #f1f1f1;
}
.to-jack-btn:hover {
  background-color: #d1d1d1;
}
.role-btn {
  border-radius: 40px;
  background: var(--Primary-White, #fff);
}
.role-btn:hover {
  background-color: #5a5a5a;
}

/* switch role modal */
.container {
  border-radius: 12px;
  border: 1px solid var(--Neutral-400, #f1f1f1);
  background: var(--White, #fff);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px 0px rgba(22, 34, 51, 0.08);
  margin-right: 32px;
  margin-top: 0;
  width: 420px;
  position: absolute;
  top: 8vh;
  right: 0;
}
.outer {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.header {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid var(--Neutral-400, #f1f1f1);
  justify-content: space-between;
  width: 100%;
}
.title {
  font-size: 12px;
}
.role-card {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-500, #e6e6e8);
  background: var(--White, #fff);
}
.card-left-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.card-title-container {
  display: flex;
  padding: 2px 8px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  background: var(--Neutral-900, #343434);
}
.card-title {
  text-align: center;
  font-size: 12px;
  line-height: 16px; /* 133.333% */
}
.icon-container {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  /* border: 1px solid var(--Neutral-500, #e6e6e8); */
}

/* Setup Roles Modal */
.setup-outer {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(3px);
  /* margin-top: 56px; */
}
.setup-modal {
  width: 585px;
  height: 584px;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 16px 32px 0px #16223329;
  box-shadow: 0px 4px 12px -4px #1622331f;
  opacity: 1;
  margin: auto;
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-delay: 1s;
}
.close-btn {
  display: flex;
  justify-content: flex-end;
}
.body-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setup-modal-body {
  /* margin-top: 16px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  height: calc(100% - 100px);
}
.other-guides-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  text-align: center;
}
.other-guides {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-500, #e6e6e8);
  background: var(--Neutral-100, #fff);
}
.loading-container {
  position: absolute;
  width: 585px;
  height: 584px;
  top: 12.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  opacity: 0.7;
  background-color: white;
  z-index: 1;
}
