.modal {
  width: 440px;
  position: absolute;
  left: 50%;
  top: 5vh;
  transform: translateX(-50%);
}

.title {
  text-align: center;
}

.modal-container {
  border-bottom: 1px solid E6E6E8;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn-container {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--neutral500);
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--neutral100);
}

.close-btn-container:hover {
  background-color: var(--neutral400);
}

.icon {
  margin: 16px;
}

.modal-bottom {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-top: 1px solid #e6e6e8;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.bottom-link {
  padding: 10px;
  text-align: center;
}
.todo-card-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-500, #e6e6e8);
  background: var(--Neutral-100, #fff);
  box-shadow: none;
}
.todo-card-icon {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 28px;
  background: var(--Neutral-500, #e6e6e8);
  width: 24px;
  height: 24px;
}
.todo-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.todo-card-left {
  display: flex;
  align-items: center;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.showme-btn {
  padding: 10px 8px;
  border-radius: 32px;
  border: 1px solid var(--Neutral-400, #f1f1f1);
  background: var(--Neutral-100, #fff);
}
